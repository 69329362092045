import { useState } from "react"
import type { ChangeEvent } from "react";

export default function SearchBar({ posts }: { posts: any }) {
    const [searchTerm, setSearchTerm] = useState("");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };


    function SearchResults({ filter }: { filter: string }) {

        type AnyObject = { [key: string]: any };

        function filterByText(arr: AnyObject[], searchText: string): AnyObject[] {
            const searchTerms = searchText.toLowerCase().split(' ');

            return arr.filter(obj =>
                searchTerms.every(term =>
                    Object.values(obj).some(value =>
                        String(value).toLowerCase().includes(term)
                    )
                )
            );
        }

        const filteredResults = filterByText(posts, filter);

        if (filter.length > 2) {
            return (
                <>
                    <div className="bg-mnp-light-violet/20 p-2 rounded mt-2">
                        {filteredResults.length > 0 ? (

                            <ul className="space-y-2 divide-y divide-mnp-light-violet/10 max-h-[26rem] overflow-scroll">
                                {filteredResults.map((page) => (

                                    <li className="py-2 px-2 hover:bg-mnp-light-violet/70 hover:rounded transition-all">
                                        <a href={"/" + page.source + "/" + page.slug} className="flex gap-x-4">
                                            <div
                                                className="flex-none w-12 h-12 rounded bg-cover bg-center z-10 group-hover:scale-125 transition-all duration-500"
                                                style={{ backgroundImage: "url(" + page.previewImage.url + ")" }}
                                            ></div>
                                            <div className="flex-grow leading-5">
                                                <p className="text-xs text-mnp-yellow">{page.type}</p>
                                                <p className="text-sm sm:text-md text-mnp-white">{page.title}</p>
                                                <p className="text-xs text-mnp-light-violet">{"menotpause.com/" + page.source + "/" + page.slug}</p>
                                            </div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-mnp-white text-center text-sm sm:text-md">Keine Ergebnisse für den Suchbegriff: {filter}</p>
                        )}
                    </div>
                </>
            )
        }
        else return null
    }

    return (
        <>
            <div className="flex gap-2">
                <input type="text" value={searchTerm} onChange={handleChange} placeholder="Suchen…" className="w-full h-12 bg-mnp-light-violet rounded outline-none px-3 placeholder:text-mnp-medium-violet" />
                {searchTerm.length > 0 && (
                    <div onClick={() => setSearchTerm("")} className="text-mnp-white px-3 pt-3 bg-mnp-yellow rounded cursor-pointer">
                        <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>

                    </div>
                )}
            </div>
            <SearchResults filter={searchTerm} />
        </>
    )
}